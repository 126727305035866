<div class="popup-cookies" id="popup-cookies" *ngIf="showCookiePolicyPopup === true">
  <div class="popup-title">{{ translations.get('cookie_policy') }}</div>
  <div class="popup-info">
    <div class="row">
      <div class="col-12 col-xl-9">
        <div class="popup-text">
          <span class="text-muted">
            {{ translations.get('privacy_policy_popup_text') }}
          </span>
          <span style="cursor: pointer; color:#3D6493" (click)="legalAgreementsLib.showCookiePolicy()">
            {{ translations.get('cookie_policy') }}
          </span>
        </div>
      </div>
      <div class="col-12 col-xl-3 text-end">
        <button type="submit"class="btn btn-primary" (click)="acceptCookiesPolicy()">
          {{ translations.get('accept') }}
        </button>
      </div>
    </div>
  </div>
</div>
