import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import {
  CheckAuthUserDataModel,
  CountriesDataModel,
  LatestLegalAgreementsVersionDataModel,
  LegalAgreementsDataModel,
  ResendCodeModel,
  ResendCodeResponseModel,
  ResetPasswordModel,
  UserLegalAgreementsVersionDataModel,
} from './auth.model';
import { UserDataModel } from '../billing/billing-addresses.model';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private restApiService: RestApiService;
  private sessionLib: SessionLibService;
  legalAdviceTitle = '';
  legalAdviceText = '';
  cookiePolicyTitle = '';
  cookiePolicyText = '';
  privacyPolicyTitle = '';
  privacyPolicyText = '';

  constructor(restApiService: RestApiService, sessionLib: SessionLibService) {
    this.restApiService = restApiService;
    this.sessionLib = sessionLib;
  }

  public getCountries(): Observable<CountriesDataModel> {
    return new Observable((observer) => {
      this.restApiService.getCountries().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getLegalAgreementsText(): Observable<LegalAgreementsDataModel> {
    return new Observable((observer) => {
      this.restApiService.getLegalAgreementsText().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getLatestLegalAgreementsVersion(): Observable<LatestLegalAgreementsVersionDataModel> {
    return new Observable((observer) => {
      this.restApiService.getLegalAgreementsVersion().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getUserLatestLegalAgreements(
    data
  ): Observable<UserLegalAgreementsVersionDataModel> {
    return new Observable((observer) => {
      this.restApiService.getUserLegalAgreementsVersion(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public putUserLatestLegalAgreements(
    vid,
    data
  ): Observable<UserLegalAgreementsVersionDataModel> {
    return new Observable((observer) => {
      this.restApiService.putUserLegalAgreementsVersion(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getUserData(): Observable<UserDataModel> {
    return new Observable((observer) => {
      this.restApiService.getUserData().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public createRegister(data: UserDataModel): Observable<UserDataModel> {
    return new Observable((observer) => {
      this.restApiService.postRegister(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public confirmCode(data): Observable<UserDataModel> {
    return new Observable((observer) => {
      this.restApiService.confirmCode(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public resendCodeEmail(data): Observable<ResendCodeResponseModel> {
    return new Observable((observer) => {
      this.restApiService.resendCode(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public resetPasswordEmail(data): Observable<ResendCodeModel> {
    return new Observable((observer) => {
      this.restApiService.resetPasswordEmail(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public resetPasswordNewPassword(data): Observable<ResetPasswordModel> {
    return new Observable((observer) => {
      this.restApiService.resetPassword(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public login(data): Observable<UserDataModel> {
    return new Observable((observer) => {
      this.restApiService.login(data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public checkAuth(): Observable<CheckAuthUserDataModel> {
    return new Observable((observer) => {
      this.restApiService.getCheckUserAuth().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  getLegalAgreements() {
    this.getLegalAgreementsText().subscribe((response) => {
      this.legalAdviceTitle = response.legal_advice_title;
      this.legalAdviceText = response.legal_advice_text;

      this.cookiePolicyTitle = response.cookie_policy_title;
      this.cookiePolicyText = response.cookie_policy_text;

      this.privacyPolicyTitle = response.privacy_policy_title;
      this.privacyPolicyText = response.privacy_policy_text;
    });
  }

  checkUserLegalAgreementsVersion(userVid: string) {
    // Get latest
    this.getLatestLegalAgreementsVersion().subscribe((responseLatest) => {
      const legalVersion = responseLatest.latest_version;

      // Get user's accepted version
      this.getUserLatestLegalAgreements(userVid).subscribe(
        (responseUserLatest) => {
          const userLegalVersion =
            responseUserLatest.accepted_legal_agreement_version;

          // Not accepted
          if (userLegalVersion < legalVersion) {
            // Get text
            this.getLegalAgreementsText().subscribe((response) => {
              Swal.fire({
                title: response.legal_advice_title,
                text: response.legal_advice_text,
                confirmButtonColor: '#224261',
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.putUserLatestLegalAgreements(
                    this.sessionLib.getSessionVid(),
                    legalVersion
                  ).subscribe((legal) => {});
                }
              });
            });
          }
        }
      );
    });
  }

  doLogin(user: UserDataModel) {
    this.sessionLib.createSession(
      user.vid,
      user.name,
      user.auth_response.token,
      user.email,
      user.auth_response.access_token,
      user.type,
      user.language,
      false
    );

    environment.language = user.language;
    const initialName = user.name[0];
    const initialSurname = user.surname[0];
    const avatar = {
      name: initialName + initialSurname,
      background: user.avatar_colors[0],
      color: user.avatar_colors[1],
    };
    this.sessionLib.setAvatar(avatar);
    environment.language = user.language;
    this.checkUserLegalAgreementsVersion(this.sessionLib.getSessionVid());
  }
}
