import { Component, OnInit } from '@angular/core';
import { LegalAgreementsLibService } from 'src/app/services/libraries/legal-agreements-lib.service';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-popup-cookies',
  templateUrl: './popup-cookies.component.html',
  styleUrls: ['./popup-cookies.component.scss'],
})
export class PopupCookiesComponent implements OnInit {
  showCookiePolicyPopup = false;

  constructor(
    public translations: TranslationsLibService,
    public session: SessionLibService,
    public legalAgreementsLib: LegalAgreementsLibService
  ) {
    this.showCookiePolicyPopup = session.getCookiesPolicy() !== 'true';
  }

  ngOnInit(): void {}

  acceptCookiesPolicy() {
    this.showCookiePolicyPopup = false;
    this.session.setCookiesPolicy('true');
  }
}
