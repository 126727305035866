import { EventEmitter, Inject, Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  ActivationStart,
  NavigationEnd,
  NavigationStart,
  Router,
  Scroll,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RouteLib {
  currentHash = '';
  previousHash = '';
  previousHash2 = '';
  mapHashes = [
    'fields-general',
    'field',
    'create-field',
    'edit-field',
    'terminal-activation',
    'terminals-general',
    'edit-terminal',
    'terminals-edit',
    'weather',
  ];
  navigationLifecycleIsRunning: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {}

  navigationStart() {
    this.router.events.subscribe((event) => {
      /*if (event instanceof NavigationEnd) {
        console.log('%1');
      }*/
      if (event instanceof NavigationStart) {
        this.emitNavigationLifecycleIsRunning(true);
      } else if (event instanceof Scroll) {
        const that = this;
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        setTimeout(function () {
          that.emitNavigationLifecycleIsRunning(false);
        }, 200);
      }
    });
  }
  emitNavigationLifecycleIsRunning(running) {
    this.navigationLifecycleIsRunning.emit(running);
  }
  getNavigationLifecycleIsRunningEmitter() {
    return this.navigationLifecycleIsRunning;
  }
  /**
   * Allows/Hack to bypass Google Maps errors on Android flickering
   * If previous hash is a map hash, force full reload
   */
  androidForceFullReloadDependingOnMapHashes() {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        this.currentHash = event.snapshot.routeConfig.path;
        this.previousHash2 = this.previousHash;
        this.previousHash = this.currentHash;
        if (
          //this.mapHashes.includes(this.currentHash) &&
          this.mapHashes.includes(this.previousHash2)
        ) {
          /*window.location.href = this.getResolvedUrl(event.snapshot);*/
          if (
            (this.previousHash.includes('weather') &&
              this.previousHash2.includes('field')) ||
            (this.previousHash2.includes('weather') &&
              this.previousHash.includes('field'))
          ) {
            console.log(
              '[RouteLib]: androidForceFullReloadDependingOnMapHashes #1'
            );
          } else {
            if (this.previousHash.includes('edit')) {
              console.log(
                '[RouteLib]: androidForceFullReloadDependingOnMapHashes #2'
              );
            }
            /*if(this.previousHash.includes('create-field')){
              if(window['map']) {
                console.log('destruyo')
                window['map'].destroy();
              }
            }
            if(this.previousHash2.includes('create-field')){
              console.log('estou aqu');
              window['map'].destroy();
              window.location.href = this.getResolvedUrl(event.snapshot);
            }*/
            else {
              // eslint-disable-next-line @typescript-eslint/dot-notation
              if (window['map']) {
                console.log(
                  '[RouteLib]: androidForceFullReloadDependingOnMapHashes #3'
                );
                try {
                  // eslint-disable-next-line @typescript-eslint/dot-notation
                  window['map'].destroy();
                } catch (error) {
                  console.log("[RouteLib]: Map couldn't be destroyed #3");
                }
                // eslint-disable-next-line @typescript-eslint/dot-notation
                setTimeout(() => {
                  window.location.href = this.getResolvedUrl(event.snapshot);
                }, 100);
              }
            }
          }
        } else {
          console.log('[RouteLib]: @4', this.previousHash, this.previousHash2);

          // eslint-disable-next-line @typescript-eslint/dot-notation
          if (window['map']) {
            console.log(
              '[RouteLib]: androidForceFullReloadDependingOnMapHashes #4'
            );
            try {
              // eslint-disable-next-line @typescript-eslint/dot-notation
              window['map'].destroy();
            } catch (error) {
              console.log("[RouteLib]: Map couldn't be destroyed #4");
            }
            /*setTimeout(()=>{
              window.location.href = this.getResolvedUrl(event.snapshot);
            },200);*/
          }
        }
      }
    });
  }

  /**
   * Allows/Hack to bypass Google Maps errors on iOS gesture back
   */
  iOSForceFullReloadDependingOnMapHashes() {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        // Farming URLs retrieve two ActivationStartEvents, first one is ok but the second event has a trimmed empty path
        if (event.snapshot.routeConfig.path.trim() !== '') {
          let proposedNewCurrentHash = event.snapshot.routeConfig.path;
          Object.keys(event.snapshot.params).forEach((k, index) => {
            proposedNewCurrentHash += '_' + k + '_' + event.snapshot.params[k];
          });

          if (
            proposedNewCurrentHash === this.previousHash2 &&
            this.mapHashes.includes(event.snapshot.routeConfig.path)
          ) {
            console.log(
              '[RouteLib.forceFullReloadDependingOnMapHashes]: proposedNewCurrentHash ' +
                proposedNewCurrentHash +
                ' this.previousHash2 ' +
                this.previousHash2 +
                ' event.snapshot.routeConfig.path ' +
                event.snapshot.routeConfig.path
            );
            window.location.href = this.getResolvedUrl(event.snapshot);
          }

          this.currentHash = proposedNewCurrentHash;
          this.previousHash2 = this.previousHash;
          this.previousHash = this.currentHash;
        }
      }
    });
  }
  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .join('/');
  }
}
