//@ts-check
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppCommonsModule } from './commons/app-commons.module';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthModule } from './auth/auth.module';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from '@nutricontrol/app360-shared';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CanActivateViaAuthGuard } from './services/guards/guards';
import { SwiperModule } from 'swiper/angular';
import { NgxMaskModule } from 'ngx-mask';
import { CodeInputModule } from 'angular-code-input';
import { App360BillingSharedModule } from '@nutricontrol/app360-billing-shared';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicModule,
    AuthModule,
    HttpClientModule,
    CodeInputModule,
    NoopAnimationsModule,
    SwiperModule,
    NgxMaskModule.forRoot(),
    AppCommonsModule,
    App360BillingSharedModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FormBuilder,
    GoogleAnalytics,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    CanActivateViaAuthGuard,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
