import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { IonicModule } from '@ionic/angular';
import { AuthService } from './auth.service';
import { LoginComponent } from './login/login.component';
import { CodeInputModule } from 'angular-code-input';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordResetUserInvitationComponent } from './password-reset-user-invitation/password-reset-user-invitation.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { LanguageComponent } from './language/language.component';
import { AppCommonsModule } from '../commons/app-commons.module';

@NgModule({
  declarations: [
    PasswordStrengthComponent,
    LoginComponent,
    PasswordResetComponent,
    PasswordResetUserInvitationComponent,
    LanguageComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    FormsModule,
    CodeInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    AppCommonsModule,
  ],
  exports: [PasswordStrengthComponent],
  providers: [AuthService, PasswordStrengthComponent],
})
export class AuthModule {}
