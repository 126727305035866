<div class="navigation mx-2 m-lg-4">
  <nav mat-tab-nav-bar *ngIf="ready === true">
    <a mat-tab-link *ngFor="let tab of menu"
      [routerLink]="[tab.page]"
      [active]="checkActive(tab.page)"
    >
      <ion-icon style="margin-top: 0px;" slot="start" [ios]="tab.icon + '-outline'" [md]="tab.icon + '-outline'"></ion-icon>
      <span class="mx-3">
        {{ translationsLib.get('menu_' + tab.page )}}
      </span>
    </a>
  </nav>
</div>
