import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { HelpersLibService } from './services/libraries/helpers-lib.service';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { CheckConnectionLibService } from './services/libraries/check-connection-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { AuthService } from './auth/auth.service';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import { SidebarLibService } from './services/libraries/sidebar-lib.service';
import { RouteLib } from './services/libraries/route-lib';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  translationsLoaded = false;
  translationsEvent: any;
  navigationLifecycleIsRunningEvent: any;
  showSidebar = false;
  showQueryIsLoading = false;
  isNative = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private helpers: HelpersLibService,
    private platformLib: PlatformLibService,
    public session: SessionLibService,
    public translations: TranslationsLibService,
    private checkConnection: CheckConnectionLibService,
    public authService: AuthService,
    public routeLib: RouteLib,
    public sidebarLib: SidebarLibService
  ) {
    translations.initialize(true);
    this.translationsEvent = this.translations
      .getMyUCROPTranslationsLoadedEmitter()
      .subscribe((translationsLoaded) =>
        this.changeTranslationsLoaded(translationsLoaded)
      );

    checkConnection.initialize();

    environment.language = this.helpers.getUsersLocale('en');

    // Hacks depending on hashes
    this.routeLib.navigationStart();
    this.navigationLifecycleIsRunningEvent = this.routeLib
      .getNavigationLifecycleIsRunningEmitter()
      .subscribe((navigationLifecycleIsRunning) =>
        this.navigationLifecycleIsRunningEventChanger(
          navigationLifecycleIsRunning
        )
      );

    if (this.session.getShowAlert()) {
      Swal.fire({
        toast: true,
        icon: 'warning',
        title: 'Está conectado como: ' + this.session.getSessionEmail(),
        position: 'bottom-left',
        showConfirmButton: false,
        background: '#FFFF00',
        iconColor: '#000000',
        color: '#000000',
        timer: 300,
        width: 'auto',
        didOpen: () => {
          Swal.toggleTimer();
        },
      });
    }
  }
  changeTranslationsLoaded(translationsLoaded: boolean) {
    this.translationsLoaded = translationsLoaded;
  }
  navigationLifecycleIsRunningEventChanger(
    navigationLifecycleIsRunning: boolean
  ) {
    this.showQueryIsLoading = navigationLifecycleIsRunning;
  }
  ngOnInit() {
    this.showSidebar = this.sidebarLib.showSidebar();
    const that = this;
    setTimeout(() => {
      that.showSidebar = that.sidebarLib.showSidebar();
    }, 1000);
  }
}
