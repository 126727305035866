<app-loader *ngIf="translationsLoaded === false"></app-loader>
<ion-app *ngIf="translationsLoaded === true" style="justify-content: normal;">

  <meta charset="utf-8" />

  <aside id="routing-is-loading" *ngIf="showQueryIsLoading">
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  </aside>

    <ng-container *ngIf="session.getSessionToken() !== null">
      <app-main-header></app-main-header>
      <app-navigation></app-navigation>
    </ng-container>

    <ion-router-outlet id="main-content"
      [ngClass]="{'mt-7': session.getSessionToken()}"
    ></ion-router-outlet>

    <lib-billing-popup [platform]="'my-ucrop'"></lib-billing-popup>
    <app-popup-cookies></app-popup-cookies>

</ion-app>
