<ion-header >
  <ion-toolbar class="barra-superior">
    <ion-buttons slot="start" class="d-none d-md-flex">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <h1 *ngIf="name!==''">{{name}} <span *ngIf="breadcrumb!==''" class="text-muted"> <span style="font-size: 12px">></span> {{breadcrumb}} </span></h1>
    <ion-buttons slot="end">
      <ion-avatar class="p-3" role="button" (click)="router.navigate(['/profile'])">
        <app-text-avatar *ngIf="avatar !== undefined"
          [text]="avatar?.name || '!'"
          [color]="avatar?.background || 'yellow'"
          [textColor]="avatar?.color || 'black'"
        ></app-text-avatar>
      </ion-avatar>
    </ion-buttons>
    <ion-title>
    </ion-title>
  </ion-toolbar>
</ion-header>
